.legend--dbclick {
  .highcharts-legend-item .highcharts-graph:not(:only-child) {
    display: inline;
  }
}

.label--capitalize {
  .highcharts-xaxis-labels span::first-letter {
    text-transform: uppercase;
  }
}

[data-highcharts-chart],
.highcharts-container {
  overflow: visible !important;
}

[data-highcharts-chart] {
  position: relative;
  z-index: 1;
}
