@import '~styles/palette.scss';

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loader {
  display: grid;
  place-content: center;
  height: 100%;
  min-height: 80px;
  color: palette(white);

  svg {
    width: 80px;
    height: 80px;
    animation: spin 1s linear 0s infinite;
  }

  &.smallIcon {
    min-height: 60px;

    svg {
      width: 60px;
      height: 60px;
    }
  }

  &.background {
    color: palette(bodybg);
  }

  &.fullScreen {
    @extend .background;

    height: 100vh;
  }

  &.customerExperience {
    min-height: 550px;
  }

  &.insightsBriefings {
    min-height: 180px;
  }
}
