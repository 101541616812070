/* stylelint-disable no-descending-specificity */
html,
body {
  -webkit-overflow-scrolling: touch;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Styled scrollbar
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  // background: $primary-color;
  background: $primary;
  border-radius: 5px;

  &:hover {
    background: darken($primary, 5);
  }
}

// Drop autofill
:-webkit-autofill,
:-webkit-autofill:active {
  box-shadow: inset 0 -1px 0 palette(gray40), inset 0 0 0 500px palette(white) !important;
  // -webkit-text-fill-color: $black;
  transition: background-color 250ms ease-in-out, border-color 250ms ease-in-out,
    box-shadow 250ms ease-in-out;
  // animation: autofill 0s forwards;
}

:-webkit-autofill:hover {
  box-shadow: inset 0 0 0 palette(gray40), 0 0 0 500px palette(gray10) inset !important;
}

:-webkit-autofill:focus {
  box-shadow: inset 0 -2px 0 palette(green60), 0 0 0 500px palette(white) inset !important;
}

:-internal-autofill-previewed {
  font-size: 1.125rem !important;
}

[type='search']::-webkit-search-decoration,
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-results-button,
[type='search']::-webkit-search-results-decoration {
  appearance: none;
}

.wrap {
  display: flex;
  flex-direction: column;
  // overflow: hidden;
  min-width: 1200px;
  // flex-direction: column;
  // align-items: stretch;
  // justify-content: flex-start;
  min-height: 100vh;
  letter-spacing: 0.01em;

  &--legacy_dashboard {
    overflow: hidden;
  }

  &--service {
    align-items: center;
    justify-content: center;
    background: palette(gray10);
  }

  &--login {
    padding: 70px 0;
  }
}

a {
  transition: color 200ms;
}

.js-off-canvas-overlay {
  -webkit-tap-highlight-color: transparent;
}

img {
  max-width: 100%;
}

svg {
  display: block;
}

// .text-muted{

// }

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  letter-spacing: 0.02em;
}

h2,
.h2 {
  color: palette(gray80);
}

h3,
.h3 {
  color: palette(gray80);
}

.lead {
  line-height: 1.34;
}

[type='radio'] {
  display: none;

  + label {
    position: relative;
    flex: 1 1 100%;
    padding: 0 8px 8px;
    text-align: center;
    cursor: pointer;
    user-select: none;
    box-shadow: inset 0 -1px 0 palette(gray40);
    transition: 250ms;

    ~ label {
      &::before {
        position: absolute;
        bottom: 8px;
        left: 0;
        height: 24px;
        content: '';
        border-left: 1px solid palette(gray20);
      }
    }
  }

  &:checked + label {
    font-weight: 500;
    color: palette(green70);
    box-shadow: inset 0 -2px 0 palette(green60);
  }

  &:disabled + label {
    color: palette(gray30);
    cursor: not-allowed;
  }
}

:disabled {
  cursor: not-allowed;
}

.smcx-embed,
.smcx-iframe-container {
  max-width: none !important;
}

.modal-backdrop {
  width: 100%;
  height: 100%;
  background-color: palette(black);
}

.container {
  width: 100%;
  max-width: 1200px;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
