/* stylelint-disable scss/at-extend-no-missing-placeholder */
@import '~styles/palette';

$animation-duration: 250;

@mixin exports-background {
  background-color: rgba(palette(black), 0.65);
  backdrop-filter: blur(6px);

  // Mozilla Firefox
  @supports not (backdrop-filter: blur(6px)) {
    background-color: rgba(palette(black), 0.7);
  }
}

@mixin shadow {
  box-shadow: 0 4px 14px rgba(0, 0, 0, 10%), 0 1px 3px rgba(0, 0, 0, 7%),
    0 1px 2px rgba(0, 0, 0, 5%), 0 2px 5px rgba(0, 0, 0, 4%);
}

.tooltip {
  position: absolute;
  z-index: 1060;
  display: grid;
  grid-auto-flow: row;
  gap: 8px;
  padding: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: palette(white);
  text-align: left;
  text-transform: none;
  letter-spacing: 0.01em;
  white-space: normal;
  pointer-events: none;
  cursor: auto;
  visibility: hidden;
  border-radius: 4px;
  opacity: 0;
  transition: opacity #{$animation-duration}ms,
    visibility #{$animation-duration}ms;

  &.show {
    visibility: visible;
    opacity: 1;
  }

  &.left {
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
  }

  &.right {
    top: 0;
    left: calc(100% + 10px);
  }

  &.bottom {
    top: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
  }

  &.brandTop {
    bottom: calc(100% - 14px);
    left: 50%;
    transform: translateX(-50%);
  }

  &.brandBottom {
    top: calc(100% - 14px);
    left: 50%;
    transform: translateX(-50%);
  }

  &.bottomEnd {
    top: calc(100% + 10px);
    left: 100%;
    transform: translateX(-50%);
  }

  &.bottomRight {
    top: calc(100% + 10px);
    right: 0;
  }

  &.bottomStart {
    top: calc(100% + 10px);
    left: 0;
  }

  &.sm {
    width: 190px;
  }

  &.lg {
    width: 244px;
  }

  &.xl {
    width: 320px;
  }

  &.xxl {
    width: 420px;
  }

  &.fitContent {
    width: fit-content;
  }

  &.maxContent {
    width: max-content;
  }
}

.title {
  font-size: 16px;
  font-weight: 500;
}

.wrapper {
  position: relative;
  display: inline-grid;
  grid-auto-flow: column;
  gap: 8px;
  align-items: center;
  color: inherit;

  &:not(.chart, .nonBuyersAnalysis, .brand, .search, .exports) {
    cursor: help;
  }

  &:not(.chart, .nonBuyersAnalysis, .brand, .search, .nav) {
    .tooltip {
      @include exports-background;

      &::before {
        @include exports-background;
      }
    }
  }

  &.fullSize {
    width: 100%;
    height: 100%;
  }

  &.posStatic {
    position: static;
  }

  &.date {
    justify-content: center;
    cursor: pointer;

    .marker {
      width: 8px;
      height: 8px;
      background: palette(orange40);
      border-radius: 50%;

      svg {
        display: none;
      }
    }
  }

  &.chart {
    .tooltip {
      @include shadow;

      color: palette(gray60);
      background-color: palette(white);
      border-radius: 2px;
    }
  }

  &.nonBuyersAnalysis {
    .tooltip {
      @include shadow;

      min-width: 268px;
      padding: 12px 16px;
      color: palette(gray60);
      background-color: palette(white);
      border-radius: 2px;
    }
  }

  &.brand {
    .tooltip {
      @include shadow;

      padding: 6px 9px;
      background-color: palette(white);
      border-radius: 4px;
    }
  }

  &.search {
    .tooltip {
      @include shadow;

      width: 256px;
      padding: 12px 16px;
      line-height: 1.71;
      color: palette(gray80);
      background-color: palette(white);
      border-radius: 2px;
    }
  }

  &.nav {
    .tooltip {
      @include shadow;

      padding: 20px 24px 18px;
      font-size: 18px;
      font-weight: 400;
      line-height: 1.33;
      color: palette(gray80);
      letter-spacing: 0.01em;
      background-color: palette(white);
    }
  }

  &.exports {
    order: -1;

    .tooltip {
      max-width: 240px;
      text-align: center;

      &::before {
        position: absolute;
        top: -6px;
        left: 50%;
        width: 12px;
        height: 6px;
        clip-path: polygon(100% 100%, 50% 0, 0 100%);
        content: '';
        transform: translateX(-50%);
      }
    }
  }
}

/* stylelint-disable-next-line selector-pseudo-class-no-unknown */
:export {
  /* stylelint-disable-next-line property-no-unknown */
  delay: $animation-duration;
}
