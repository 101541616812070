/* stylelint-disable no-descending-specificity */

.form {
  &--login {
    margin-top: 30px;

    .field {
      margin-right: -12px;
      margin-left: -12px;
    }
  }

  &_notify {
    margin-top: 36px;
    font-size: 14px;
    line-height: 1.43;
    color: palette(gray70);
    opacity: 1;
    transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);

    &:empty {
      max-height: 0;
      margin-top: 0;
      overflow: hidden;
      opacity: 0;
    }

    &--error {
      color: palette(orange60);
    }
  }

  &-control[type] {
    position: relative;
    height: auto;
    letter-spacing: 0.01em;
    border-right: 12px solid transparent;
    border-left: 12px solid transparent;
    box-shadow: inset 0 -1px 0 palette(gray40);
    transition: background-color 250ms ease-in-out,
      border-color 250ms ease-in-out, box-shadow 250ms ease-in-out;

    &::placeholder {
      color: transparent;
    }

    &:hover:not(:disabled) {
      background-color: palette(gray10);
      border-color: palette(gray10);
      box-shadow: inset 0 -1px 0 palette(gray10);

      ~ .field_label {
        color: palette(gray80);
      }
    }

    &:focus:not(:disabled),
    &[aria-expanded='true'] {
      background-color: palette(white);
      border-color: transparent;
      box-shadow: inset 0 -2px 0 palette(green60);

      ~ .field_label {
        color: palette(green60);
      }
    }

    &.error {
      box-shadow: inset 0 -1px 0 palette(orange60);

      ~ .field_label {
        color: palette(orange60);
      }
    }

    &.placeholder-visible::placeholder {
      color: palette(gray70);
    }
  }

  &_bottom {
    width: 100%;
    margin-top: 40px;

    &--reset {
      margin-top: 27px;
    }

    .btn {
      width: 100%;
    }
  }

  &-switch input {
    width: 40px;
    height: 22px;
    background-color: palette(gray20);
    border: 1px solid palette(gray20);

    &:checked {
      background-color: palette(green50);
      border-color: palette(green50);
    }

    &:hover,
    &:focus {
      border-color: transparent;
    }
  }

  &-check-input {
    display: block;
    margin-top: 0;
    cursor: pointer;

    &:hover,
    &:focus {
      border-color: palette(green60);
    }
  }
}

.field {
  min-height: 56px;
  padding: 6px 12px 0;
  margin-bottom: 16px;
  border-radius: 4px;

  &--filter {
    flex: 0 0 33.33%;
    margin-bottom: 24px;
  }

  &--full {
    flex: 1;
  }

  &--twothird {
    flex: 0 0 66.66%;
  }

  &--half {
    flex: 0 0 50%;
  }

  &--quarter {
    flex: 0 0 25%;
  }

  &--space {
    margin-bottom: 32px;
  }

  &--radio {
    .field {
      &_label {
        font-size: 14px;
        line-height: 1.15;
      }

      &_input {
        display: flex;
      }
    }
  }

  &--publicMarketing {
    label {
      flex: 1 1 auto;
      min-width: 158px;
      padding-right: 20px;
      padding-left: 20px;
    }
  }

  &--tabs {
    min-height: auto;
    padding: 6px 0 0;
    margin: 0;
  }

  &--select {
    position: relative;
    transition: all 250ms;

    .field {
      &_label {
        position: absolute;
        top: 8px;
        font-size: 14px;
        line-height: 1.15;
        cursor: text;
        transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);

        &--error {
          color: palette(orange60);
        }

        &.is_empty {
          top: 27px;
          font-size: 18px;
        }
      }

      &_input {
        min-width: 200px;
        margin-top: 12px;
      }
    }

    &.full {
      flex: 0 0 100%;
    }

    &.half {
      flex: 0 0 50%;
      width: 50%;
    }

    &.hidden {
      flex: 0 0 0;
      width: 0;
      padding-right: 0;
      padding-left: 0;
      overflow: hidden;
      opacity: 0;
    }
  }

  &--text,
  &--search {
    position: relative;

    .field {
      &_label {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        cursor: text;
        transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
        transform: translate(12px, -8px);

        &--picker {
          top: 12px;
          bottom: unset;
          font-size: 14px;
        }

        &--error {
          color: palette(orange60);
        }
      }

      &_input {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: flex-end;
        width: 100%;
        height: 100%;
        margin: 0;
      }
    }

    .form-control {
      width: 100%;
      height: calc(100% - 16px);
      padding-top: 8px;
      transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94);

      &:focus,
      &:not(:placeholder-shown),
      &:-webkit-autofill {
        height: 100%;
        padding-top: 24px;

        ~ .field_label {
          font-size: 14px;
          line-height: 1.15;
          transform: translate(12px, -34px);
        }
      }
    }
  }

  &--sub {
    position: relative;
    padding: 0;

    .field_input {
      margin-top: 0;
    }

    .form-control {
      padding-top: 40px;

      &::placeholder {
        color: palette(gray70);
      }
    }

    .field_label {
      position: absolute;
      top: 7px;
      left: 12px;
      color: palette(black);
      letter-spacing: 0.01em;
      cursor: text;

      &--error {
        color: palette(orange60);
      }
    }
  }

  &--brand {
    margin: -16px -12px 0;

    &.active {
      .form-control {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'%3E%3C/svg%3E%0A");
      }

      .field_reset {
        visibility: visible;
        opacity: 1;
        transform: rotate(0deg);
      }
    }

    &.change_brand {
      min-height: 40px;
      margin: 0 0 8px;

      .form-control {
        height: 100%;
        padding: 0 12px;
        background-color: palette(gray10);
        background-position: right 8px bottom 8px;
        border: none;
        border-radius: 4px;
        box-shadow: none;

        &:hover,
        &:focus {
          padding-top: 0;
          background-color: palette(gray10);
          box-shadow: none;
        }

        &::placeholder {
          color: palette(gray50);
        }

        &:not(:placeholder-shown) {
          padding-top: 0;
        }
      }

      .field_reset {
        right: 8px;
      }
    }

    &.insightsBriefings {
      min-height: 91px;
      padding: 0;
      margin: 0;
      border-bottom: 1px solid palette(gray30);
      border-radius: 0;

      .form-control {
        height: 90px;
        padding: 0 40px;
        font-size: 22px;
        letter-spacing: 0.02em;
        background-color: transparent;
        background-position: right 40px top 34px;
        border: none;
        box-shadow: none;

        &:hover,
        &:focus {
          background-color: transparent;
          box-shadow: none;
        }

        &::placeholder {
          font-size: 22px;
          font-weight: 500;
          color: palette(gray50);
          letter-spacing: 0.02em;
        }
      }

      .field_reset {
        right: 40px;
        bottom: 33px;
      }

      .multiline {
        position: relative;

        .form-control {
          min-height: 90px;
          padding-top: 30px;
          transition: none;

          &::-webkit-scrollbar {
            width: auto;

            &-thumb {
              background-color: rgba(palette(black), 0.2);
              border-radius: 10px;

              &:hover {
                background-color: rgba(palette(black), 0.3);
              }
            }
          }
        }

        .field_reset {
          bottom: calc(100% - 58px);
        }
      }
    }
  }

  &_label {
    display: block;
    line-height: 1.34;
    user-select: none;
    transition: color 250ms ease-in-out;
  }

  &_input {
    margin-top: 3px;

    &--search {
      .form-control {
        padding-right: 24px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'%3E%3Ccircle cx='11' cy='11' r='7' stroke='%23AFB5BA' stroke-width='2'/%3E%3Cpath stroke='%23AFB5BA' stroke-width='2' d='M20 20l-4-4'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: right bottom 8px;
        background-size: 24px;
      }
    }

    &--date .form-control {
      padding-right: 24px;
      cursor: default;
      background-color: palette(white);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'%3E%3Cpath stroke='%23AFB5BA' stroke-width='2' d='M8 5V2m8 3V2'/%3E%3Cpath fill='%23AFB5BA' d='M4 5h16v5H4z'/%3E%3Crect width='16' height='16' x='4' y='5' stroke='%23AFB5BA' stroke-width='2' rx='1'/%3E%3Cpath fill='%23AFB5BA' fill-rule='evenodd' d='M9 12H7v2h2v-2zm0 4H7v2h2v-2zm2-4h2v2h-2v-2zm2 4h-2v2h2v-2zm2-4h2v2h-2v-2zm2 4h-2v2h2v-2z' clip-rule='evenodd'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: right bottom 8px;
      background-size: 24px;
    }
  }

  &_error {
    position: absolute;
    bottom: -10px;
    left: 12px;
    font-size: 14px;
    line-height: 1.15;
    color: palette(orange60);
    opacity: 0;
    transition: all 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transform: translateY(0);

    &.visible {
      opacity: 1;
      transform: translateY(10px);
    }
  }

  &_reset {
    position: absolute;
    right: 12px;
    bottom: 8px;
    color: palette(gray50);
    visibility: hidden;
    opacity: 0;
    transition: all 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transform: rotate(45deg);

    &:hover,
    &:focus {
      color: palette(gray60);
      outline: none;
    }
  }
}

.custom-control {
  &-label {
    cursor: pointer;
  }

  &-input {
    right: 0;
    left: auto;
    height: 1rem;
  }
}

.checkbox-indeterminate input[type='checkbox'] {
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='2' viewBox='0 0 10 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 1H10' stroke='white' stroke-width='2' stroke-linejoin='round'/%3E%3C/svg%3E%0A"),
    linear-gradient(palette(green60), palette(green60));
  border-color: palette(green60);
}
